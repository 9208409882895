.cd-container {
/* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
width: 90%;
max-width: 1170px;
margin: 0 auto;
}
.cd-container::after {
/* clearfix */
content: "";
display: table;
clear: both;
}

#cd-timeline {
position: relative;
padding: 2em 0;
margin-top: 2em;
margin-bottom: 2em;
}
#cd-timeline::before {
/* this is the vertical line */
content: "";
position: absolute;
top: -117px;
left: 18px;
height: 110%;
width: 2px;
background: #00c1c5;
}
@media only screen and (min-width: 1170px) {
#cd-timeline {
  margin-top: 3em;
  margin-bottom: 3em;
}
#cd-timeline::before {
  left: 50%;
  margin-left: -2px;
}
}

@media only screen and (max-width: 768px) {
#cd-timeline::before {
  /* this is the vertical line */
  top: 50px;
}

.headline {
  position: fixed;
  left: 58%;
  margin-left: -136px;
  bottom: 50px;
  width: 220px;
  height: 350px;
}

.logoali {
  width: 100px;
  height: 181px;
  position: fixed;
  top: 40px;
  left: 40px;
}

.logoalicontact {
  width: 100px;
  height: 181px;
  position: absolute;
  top: 40px;
  left: 40px;
}
}

.cd-timeline-block {
position: relative;
margin: 2em 0;
}
.cd-timeline-block:after {
content: "";
display: table;
clear: both;
}
.cd-timeline-block:first-child {
margin-top: 0;
}
.cd-timeline-block:last-child {
margin-bottom: 0px;
}
@media only screen and (min-width: 1170px) {
.cd-timeline-block {
  margin: 4em 0;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0px;
}
}

.cd-timeline-img {
position: absolute;
top: 0;
left: 0;
width: 40px;
height: 40px;
border-radius: 50%;
box-shadow: 0 0 0 2px white, inset 0 2px 0 rgba(0, 0, 0, 0.08),
  0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline-img img {
display: block;
width: 24px;
height: 24px;
position: relative;
left: 50%;
top: 50%;
margin-left: -12px;
margin-top: -12px;
}
.cd-timeline-img.cd-picture {
background: #00394f;
}
.cd-timeline-img.cd-movie {
background: #999;
}
.cd-timeline-img.cd-location {
background: #17a2b8;
}
@media only screen and (min-width: 1170px) {
.cd-timeline-img {
  width: 60px;
  height: 60px;
  left: 50%;
  margin-left: -30px;
  /* Force Hardware Acceleration in WebKit */
  /* -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden; */
}
.cssanimations .cd-timeline-img.is-hidden {
  visibility: hidden;
}
.cssanimations .cd-timeline-img.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  -moz-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}
}

.cd-timeline-content {
position: relative;
margin-left: 60px;
background: #f8f9fa;
padding: 1em;
box-shadow: 0 2px 0 #00c1c5;
}
.cd-timeline-content:after {
content: "";
display: table;
clear: both;
}
.cd-timeline-content h2 {
font-family: "handlee", cursive;
font-size: 35px !important;
font-size: 1.25rem;
color: #00c1c5;
}
.cd-timeline-content p,
.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
font-size: 16px;
font-size: 0.8125rem;
}
.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
display: inline-block;
color: #00c1c5;
}
.cd-timeline-content p {
margin: 1em 0;
line-height: 1.6;
}
.cd-timeline-content .cd-read-more {
float: right;
padding: 0.8em 1em;
color: #00c1c5;
border-bottom: solid 2px #00c1c5;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
background-color: white;
}
.cd-timeline-content .cd-date {
float: left;
padding: 0.8em 0;
opacity: 0.7;
}
.cd-timeline-content::before {
content: "";
position: absolute;
top: 16px;
right: 100%;
height: 0;
width: 0;
border: 7px solid transparent;
border-right: 7px solid black;
}
@media only screen and (min-width: 768px) {
.cd-timeline-content h2 {
  font-family: "handlee", cursive;
  font-size: 35px !important;
  font-size: 1.25rem;
  color: #00c1c5;
}
.cd-timeline-content p {
  font-family: "nunito", sans-serif;
  font-size: 16px;
  font-size: 1rem;
}
.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
  font-size: 16px;
  font-size: 0.875rem;
}
}
@media only screen and (min-width: 1170px) {
.cd-timeline-content {
  margin-left: 0;
  padding: 1.6em;
  width: 45%;
}
.cd-timeline-content::before {
  top: 24px;
  left: 100%;
  border-color: transparent;
  border-left-color: #00394f;
}
.cd-timeline-content .cd-read-more {
  float: left;
}
.cd-timeline-content .cd-date {
  position: absolute;
  width: 100%;
  left: 122%;
  top: 6px;
  font-size: 16px;
  font-size: 1rem;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content {
  float: right;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content::before {
  top: 24px;
  left: auto;
  right: 100%;
  border-color: transparent;
  border-right-color: #00394f;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
  float: right;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
  left: auto;
  right: 122%;
  text-align: right;
}
.cssanimations .cd-timeline-content.is-hidden {
  visibility: hidden;
}
.cssanimations .cd-timeline-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  -moz-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}
}

@media only screen and (min-width: 1170px) {
/* inverse bounce effect on even content blocks */
.cssanimations
  .cd-timeline-block:nth-child(even)
  .cd-timeline-content.bounce-in {
  -webkit-animation: cd-bounce-2-inverse 0.6s;
  -moz-animation: cd-bounce-2-inverse 0.6s;
  animation: cd-bounce-2-inverse 0.6s;
}
}
.cd-container {
/* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
width: 90%;
max-width: 1170px;
margin: 0 auto;
}
.cd-container::after {
/* clearfix */
content: "";
display: table;
clear: both;
}

/*--------------------------------------------------------------
# Sponsors Section
--------------------------------------------------------------*/
#supporters {
padding: 60px 0;
}

#supporters .supporters-wrap {
border-top: 1px solid #e0e5fa;
border-left: 1px solid #e0e5fa;
margin-bottom: 30px;
}

#supporters .supporter-logo {
padding: 30px;
display: flex;
justify-content: center;
align-items: center;
border-right: 1px solid #e0e5fa;
border-bottom: 1px solid #e0e5fa;
overflow: hidden;
background: rgba(255, 255, 255, 0.5);
height: 160px;
}

#supporters .supporter-logo:hover img {
transform: scale(1.2);
}

#supporters img {
transition: all 0.4s ease-in-out;
}

/*--------------------------------------------------------------
# F.A.Q Section
--------------------------------------------------------------*/
#faq {
padding: 60px 0;
}

#faq .faq-list {
padding: 0;
list-style: none;
}

#faq .faq-list li {
border-bottom: 1px solid #e9eaed;
margin-bottom: 20px;
padding-bottom: 20px;
}

#faq .faq-list .question {
display: block;
position: relative;
font-family: #17a2b8;;
font-size: 18px;
line-height: 24px;
font-weight: 400;
padding-left: 25px;
cursor: pointer;
color:#17a2b8;
transition: 0.3s;
}

#faq .faq-list i {
font-size: 16px;
position: absolute;
left: 0;
top: -2px;
}

#faq .faq-list p {
margin-bottom: 0;
padding: 10px 0 0 25px;
}

#faq .faq-list .icon-show {
display: none;
}

#faq .faq-list .collapsed {
color: black;
}

#faq .faq-list .collapsed:hover {
color: #17a2b8;
}

#faq .faq-list .collapsed .icon-show {
display: inline-block;
transition: 0.6s;
}

#faq .faq-list .collapsed .icon-close {
display: none;
transition: 0.6s;
}

body .base {
z-index: 90;
position: fixed;
top: 0px;
left: 0px;
background-color: #fff;
width: 98px;
height: 98px;
transition: all 1s cubic-bezier(0.5, -0.75, 0.05, 1);
border-bottom-right-radius: 100%;
}
body .base .menu {
z-index: 100;
background-color: #fff;
position: absolute;
top: 0px;
left: 0px;
width: 100px;
height: 100px;
border-bottom-right-radius: 200px;
cursor: pointer;
transition: all 1s cubic-bezier(0.5, -0.75, 0.05, 1), background-color 1s ease;
}
body .base .menu .icon {
position: absolute;
width: 25px;
height: 25px;
top: 50%;
left: 50%;
transform: translate(-100%, -100%);
}
body .base .menu .icon:before,
body .base .menu .icon:after {
content: "";
transform: rotate(0deg);
transition: top 0.5s ease 0.5s, transform 0.5s ease,
  background-color 0.75s ease 0.25s;
}
body .base .menu .icon .bar,
body .base .menu .icon:before,
body .base .menu .icon:after {
position: absolute;
height: 5px;
left: 0px;
right: 0px;
border-radius: 5px;
background-color: #21264b;
}
body .base .menu .icon .bar {
transition: opacity 0s linear 0.5s, background-color 0.75s ease 0.25s;
opacity: 1;
top: 10px;
}
body .base .menu .icon:before {
top: 0px;
}
body .base .menu .icon:after {
top: initial;
top: 20px;
}
body .base .icons {
z-index: 98;
position: absolute;
top: 0px;
left: 0px;
}
body .base .icons > * {
position: absolute;
font-size: 40px;
color: #21264b;
transition: 0.3s cubic-bezier(0.5, -0.25, 0.05, 1);
}
body .base .icons .fa-user {
top: 35px;
left: 0px;
transition-delay: 0.2s;
}
body .base .icons .fa-calendar-o {
top: 0px;
left: 0px;
color: #fff;
transition-delay: 0.1s;
}
body .base .icons .fa-tachometer {
top: 0px;
left: 35px;
}
body .base .section {
z-index: 96;
position: absolute;
top: 0px;
left: 0px;
height: 0px;
width: 0px;
transform-origin: 100% 100%;
transform: rotate(135deg);
}
body .base .section .cover1 {
transform-origin: 100% 100%;
}
body .base .section .cover1,
body .base .section .cover1 .cover2,
body .base .section .cover1 .cover2 .content {
position: absolute;
width: 600px;
height: 600px;
}
body .base .section .cover1,
body .base .section .cover1 .cover2 {
top: 50%;
left: 50%;
transform: translate(-100%, -100%) rotate(4deg);
overflow: hidden;
pointer-events: none;
transition: transform 0.5s ease-in;
}
body .base .section .cover1 .cover2 {
transform: translate(50%, -50%) rotate(-8deg);
transform-origin: 0% 100%;
}
body .base .section .cover1 .cover2 .content {
width: 150px;
height: 150px;
border-radius: 100%;
background-color: #ee1b59;
top: 100%;
left: 0%;
transform: translate(-50%, -50%);
transition: background-color 0s,
  width 1.5s cubic-bezier(0.5, -0.5, 0.05, 1) 0s,
  height 1.5s cubic-bezier(0.5, -0.5, 0.05, 1) 0s;
pointer-events: auto;
}
body .base .section-static {
z-index: 94;
width: 100px;
height: 100px;
position: absolute;
top: 0px;
left: 0px;
transform-origin: 0% 0%;
transition: width 1s cubic-bezier(0.5, -0.75, 0.05, 1),
  height 1s cubic-bezier(0.5, -0.75, 0.05, 1);
}
body .base .section-static:hover {
background-color: #eaeaea;
}
body .base .section-static.top {
transform: rotate(-45deg);
border-bottom-right-radius: 400px;
}
body .base .section-static.bottom {
transform: rotate(45deg);
border-bottom-right-radius: 400px;
}
body .base.close {
width: 300px;
height: 300px;
transition: all 1.25s cubic-bezier(0.5, 0, 0.05, 1.75);
}
body .base.close .menu {
width: 150px;
height: 150px;
transition: all 1s cubic-bezier(0.5, 0, 0.05, 1.75), background-color 1s ease;
background-color: #21264b;
}
body .base.close .menu .icon .bar,
body .base.close .menu .icon:before,
body .base.close .menu .icon:after {
background-color: #fff;
}
body .base.close .menu .icon .bar {
opacity: 0;
}
body .base.close .menu .icon:before,
body .base.close .menu .icon:after {
transition: top 0.5s linear, transform 0.5s ease 0.5s,
  background-color 0.75s ease 0.25s;
}
body .base.close .menu .icon:before {
top: 10px;
transform: rotate(-45deg);
}
body .base.close .menu .icon:after {
top: 10px;
transform: rotate(45deg);
}
body .base.close .icons {
position: absolute;
top: 0px;
left: 0px;
}
body .base.close .icons > * {
position: absolute;
font-size: 40px;
color: #21264b;
transition: 0.3s cubic-bezier(0.5, 0, 0.05, 1.75) 0.7s;
pointer-events: none;
}
body .base.close .icons .fa-user {
top: 35px;
left: 200px;
}
body .base.close .icons .fa-calendar-o {
top: 141px;
left: 141px;
color: #fff;
transition-delay: 0.65s;
}
body .base.close .icons .fa-tachometer {
top: 200px;
left: 35px;
transition-delay: 0.8s;
}
body .base.close .section .cover1 {
transform-origin: 100% 100%;
}
body .base.close .section .cover1,
body .base.close .section .cover1 .cover2,
body .base.close .section .cover1 .cover2 .content {
position: absolute;
width: 600px;
height: 600px;
}
body .base.close .section .cover1,
body .base.close .section .cover1 .cover2 {
top: 50%;
left: 50%;
transform: translate(-100%, -100%) rotate(16deg);
overflow: hidden;
transition: transform 0.5s ease-in 0.5s;
}
body .base.close .section .cover1 .cover2 {
transform: translate(50%, -50%) rotate(-32deg);
transform-origin: 0% 100%;
}
body .base.close .section .cover1 .cover2 .content {
border-radius: 100%;
background-color: #ee1b59;
top: 100%;
left: 0%;
transform: translate(-50%, -50%);
transition: background-color 0s,
  width 1.1s cubic-bezier(0.5, 0, 0.05, 1.75) 0.25s,
  height 1.1s cubic-bezier(0.5, 0, 0.05, 2) 0.25s;
}
body .base.close .section .cover1 .cover2 .content:hover {
background-color: #dd1350;
}
body .base.close .section-static {
width: 300px;
height: 300px;
transition: width 1.25s cubic-bezier(0.5, 0, 0.05, 2),
  height 1.25s cubic-bezier(0.5, 0, 0.05, 2);
}
